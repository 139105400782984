import React, { useEffect, useState } from "react";
import { Drawer, Empty, Input, Spin } from "antd";
import { useSelector } from "react-redux";
import EmptyData from "../EmptyData";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import chevronLeft from "../../assets/images/svg/chevron-left.svg";
import chevroDown from "../../assets/images/svg/chevron-down.svg";
const days = ["احد", "اثنين", "ثلاثاء", "اربعاء", "خميس", "جمعة", "سبت"];
const months = [
  "يناير",
  "فبراير",
  "مارس",
  "ابريل",
  "مايو",
  "يونيو",
  "يوليو",
  "اغسطس",
  "سبتمبر",
  "اكتوبر",
  "نوفمبر",
  "ديسمبر",
];

const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => "mm/dd/yyyy",
  },
};

const DrawerCommponent = (props) => {
console.log("🚀 ~ file: index.jsx:38 ~ DrawerCommponent ~ props:", props)

  const { dataCities, loading } = useSelector((state) => state.cities);
  console.log("🚀 ~ file: index.jsx:40 ~ DrawerCommponent ~ dataCities:", dataCities)
  const { dataCountries, loading:loadingCountries } = useSelector((state) => state.countries);
  
  const [search, setNewSearch] = useState("");
  const [search2, setNewSearch2] = useState("");
  const [activeCountry, setactiveCountry] = useState({});
  // 
  const [activeCountry2, setactiveCountry2] = useState({});
  // const [activeCountry2, setactiveCountry2] = useState({ id: 2, name: "السعودية" });
  // 
  const [clocked] = useState([
    { id: "11:00:00", title: "11 صباحاً" },
    { id: "12:00:00", title: "12 ظهراً" },
    { id: "13:00:00", title: "1 مساءً" },
    { id: "14:00:00", title: "2 مساءً" },
    { id: "15:00:00", title: "3 مساءً" },
    { id: "16:00:00", title: "4 مساءً" },
    { id: "17:00:00", title: "5 مساءً" },
    { id: "18:00:00", title: "6 مساءً" },
    { id: "19:00:00", title: "7 مساءً" },
    { id: "20:00:00", title: "8 مساءً" },
    { id: "21:00:00", title: "9 مساءً" },
    { id: "22:00:00", title: "10 مساءً" },
    { id: "23:00:00", title: "11 مساءً" },
  ]);
  const [clockedActive, setClockedActive] = useState({ id: "11:00:00", title: "11 صباحاً" });
  // 
  // 
  const [day] = useState([
    { id: "1", title: " يوم" },
    { id: "2", title: " يومين" },
    { id: "3", title: "3 ايام" },
    { id: "4", title: "4 ايام" },
    { id: "5", title: "5 ايام" },
    { id: "6", title: "6 ايام" },
    { id: "7", title: "7 ايام" },
    { id: "8", title: "8 ايام" },
    { id: "9", title: "9 ايام" },
    { id: "10", title: "10 ايام" },
    { id: "11", title: "11 ايام" },
    { id: "12", title: "12 ايام" },
    { id: "13", title: "13 ايام" },
    { id: "14", title: "14 ايام" },
    { id: "15", title: "15 ايام" },
    { id: "16", title: "16 ايام" },
    { id: "17", title: "17 ايام" },
    { id: "18", title: "18 ايام" },
    { id: "19", title: "19 ايام" },
    { id: "20", title: "20 ايام" },
    { id: "21", title: "21 ايام" },
    { id: "22", title: "22 ايام" },
    { id: "23", title: "23 ايام" },
    { id: "24", title: "24 ايام" },
    { id: "25", title: "25 ايام" },
    { id: "26", title: "26 ايام" },
    { id: "27", title: "27 ايام" },
    { id: "28", title: "28 ايام" },
    { id: "29", title: "29 ايام" },
    { id: "30", title: "30 ايام" },
  ]);
  const [dayActive, setDayActive] = useState({ id: "1", title: "1 يوم" });
  // 
  // 
  const [month] = useState([
    { id: "1", title: "شهر" },
    { id: "2", title: "شهرين" },
    { id: "3", title: "3 اشهر" },
    { id: "4", title: "4 اشهر" },
    { id: "5", title: "5 اشهر" },
    { id: "6", title: "6 اشهر" },
    { id: "7", title: "7 اشهر" },
    { id: "8", title: "8 اشهر" },
    { id: "9", title: "9 اشهر" },
    { id: "10", title: "10 اشهر" },
    { id: "11", title: "11 اشهر" },
    { id: "12", title: "12 اشهر" },
  ]);
  const [monthActive, setMonthActive] = useState({ id: "1", title: " شهر" });
  // 
  const [open, setOpen] = useState(false);



  const [startDate, setStartDate] = useState(new Date());


  useEffect(() => {
    if(props?.getCountry2){
      setactiveCountry2({id:dataCountries?.data[0]?.id , name:dataCountries?.data[0]?.name})
      props?.getCountry2(dataCountries?.data[0]);
    }
  }, [dataCountries])
  
  useEffect(() => {
    if(props?.getCountry){
      setactiveCountry({id:dataCities?.data[0]?.id , name:dataCities?.data[0]?.name})
      props?.getCountry(dataCities?.data[0]);
    }
  }, [dataCities])
  

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (e) => {
    setNewSearch(e.target.value);
  };

  const clickCountry = (event) => {
    setactiveCountry(event);
    setOpen(false);
    props.getCountry(event.id);
  };

  
  const handleSearchChange2 = (e) => {
    setNewSearch2(e.target.value);
  };

  const clickCountry2 = (event) => {
    setactiveCountry2(event);
    setOpen(false);
    props.getCountry2(event);
  };


  const clickClock = (event) => {
    setClockedActive(event);
    setOpen(false);
    props.getClock(event.id);
  };

  const clickDay = (event) => {
    setDayActive(event);
    setOpen(false);
    props.getDay(event.id);
  };
  const clickMonth = (event) => {
    setMonthActive(event);
    setOpen(false);
    props.getMonth(event.id);
  };

  const changeDate = (date) => {
    setStartDate(date);
    setOpen(false);
    props.getDatePicker(date);
  };

  const filtered = !search
    ? dataCities?.data
    : dataCities?.data.filter((item) =>
        item?.name?.toLowerCase().includes(search2.toLowerCase())
      );

  const filtered2 = !search2
    ? dataCountries?.data
    : dataCountries?.data.filter((item) =>
        item?.name?.toLowerCase().includes(search2.toLowerCase())
      );

  return (
    <>
      <div className="div-Drawer" onClick={showDrawer}>
        {props?.type === "country"
          ? activeCountry
            ? activeCountry.name
            : "الرياض"
          : props?.type === "country2"
          ? activeCountry2
            ? activeCountry2.name
            : "السعو1دية"
          : props?.type === "day"
          ? dayActive
            ? dayActive.title
            : " يوم"
          : props?.type === "clock"
          ? clockedActive
            ? clockedActive.title
            : "11 صباحاً"
          : props?.type === "month"
            ? monthActive
              ? monthActive.title
              : " شهر"
          : props?.type === "datePicker"
          ? startDate
            ? new Date(startDate).getDate() +
              "/" +
              parseInt(startDate.getMonth() + 1) +
              "/" +
              startDate.getFullYear()
            : "اختر التاريخ"
          : null}
        <img src={chevroDown} alt="" />
      </div>
      <Drawer
        className="drowCustom"
        width="100%"
        title={
          props?.type === "country"
            ? "اختر المدينة"
            : props?.type === "datePicker"
            ? "اختر التاريخ"
            : null
        }
        placement="right"
        onClose={onClose}
        open={open}
      >
        {props.type === "country" ? (
          <>
            <div className="p-4">
              <Input
                placeholder="بحث"
                prefix={<i className="icon icon-search" />}
                type="text"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
            {loading ? (
              <Spin />
            ) : filtered?.length ? (
              filtered?.map((item) => {
                return (
                  <div
                    className={`widget__item-list ${
                      item.id === activeCountry?.id ? "active" : ""
                    }`}
                    onClick={() => clickCountry(item)}
                    key={item?.id}
                  >
                    <div class="d-flex align-items-center justify-content-between">
                      <h6 class="font-medium">{item?.name}</h6>
                      <img src={chevronLeft} alt="" />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mt-5">
                <EmptyData />
              </div>
            )}
          </>
        ) :
        props.type === "country2" ? (
          <>
            <div className="p-4">
              <Input
                placeholder="بحث"
                prefix={<i className="icon icon-search" />}
                type="text"
                value={search2}
                onChange={handleSearchChange2}
              />
            </div>
            {loadingCountries ? (
              <Spin />
            ) : filtered2?.length ? (
              filtered2?.map((item) => {
                return (
                  <div
                    className={`widget__item-list ${
                      item.id === activeCountry2?.id ? "active" : ""
                    }`}
                    onClick={() => clickCountry2(item)}
                    key={item?.id}
                  >
                    <div class="d-flex align-items-center justify-content-between">
                      <h6 class="font-medium">{item?.name}</h6>
                      <img src={chevronLeft} alt="" />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mt-5">
                <EmptyData />
              </div>
            )}
          </>
        ) :
         props.type === "datePicker" ? (
          <div className="text-center my-7">
            <DatePicker
              inline
              locale={locale}
              selected={startDate}
              onChange={(date) => changeDate(date)}
            />
          </div>
        ) : props.type === "clock" ? (
          <div className="text-center my-7">
            {clocked?.map((item) => {
              return (
                <div
                  className={`widget__item-list ${
                    item?.id === clockedActive?.id ? "active" : ""
                  }`}
                  onClick={() => clickClock(item)}
                  key={item?.id}
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="font-medium">{item?.title}</h6>
                    <img src={chevronLeft} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        ) : props.type === "day" ? (
          <div className="text-center my-7">
            {day?.map((item) => {
              return (
                <div
                  className={`widget__item-list ${
                    item?.id === dayActive?.id ? "active" : ""
                  }`}
                  onClick={() => clickDay(item)}
                  key={item?.id}
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="font-medium">{item?.title}</h6>
                    <img src={chevronLeft} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        ) : props.type === "month" ? (
          <div className="text-center my-7">
            {month?.map((item) => {
              return (
                <div
                  className={`widget__item-list ${
                    item?.id === monthActive?.id ? "active" : ""
                  }`}
                  onClick={() => clickMonth(item)}
                  key={item?.id}
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="font-medium">{item?.title}</h6>
                    <img src={chevronLeft} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </Drawer>
    </>
  );
};

export default DrawerCommponent;
