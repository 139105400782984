import { combineReducers } from "redux";
import { logedIn , regsiteration , verify , restPass , verifyPassword , changePassword , payment} from "./auth";
import { faq } from "./faq";
import { notifications } from "./notifications";
import { order } from "./order";
import { profile , updateProfile } from "./profile";
import { privacy , about } from "./pages";
import { cars, carDetails , subcategories , categories ,subcategoriesModel} from "./cars";
import { tickets , ticketsDetails , ticketStore , replayTicket } from "./tickets";
import { ticketTypes , countries , cities , socialMedia , whatsapp , primaryColor } from "./constants";


export default combineReducers({
  logedIn,
  restPass,
  regsiteration,
  verify,
  faq,
  cars,
  carDetails,
  tickets,
  cities,
  ticketsDetails,
  ticketTypes,
  ticketStore,
  notifications,
  privacy,
  subcategories,
  countries,
  about,
  socialMedia,
  order,
  profile,
  categories,
  updateProfile,
  replayTicket,
  whatsapp,
  verifyPassword,
  changePassword,
  payment,
  subcategoriesModel,
  primaryColor
});
