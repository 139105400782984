import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Switch,
  Input,
  Radio,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCities } from "../../store/actions/constants";
import logo from "../../assets/images/logo.svg";
import GoogleMap from "../../components/GoogleMap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import DrawerCommponent from "../../components/Drawer";

const { Option } = Select;

const Home = () => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  // const [lng, setLng] = useState();
  // const [lat, setLat] = useState();

  const [stateMap, setStateMap] = useState();

  const [form] = Form.useForm();

  const [typeSubscripe, setTypeSubscripe] = useState("day");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [country, setCountry] = useState();
  const [datePicker, setDatePicker] = useState();
  const [clock, setClock] = useState();
  const [day, setDay] = useState();
  const [month, setMonth] = useState();

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  const getMonth = (month) => {
    setMonth(month);
  };
  
  const getDay = (day) => {
    setDay(day);
  };

  const getClock = (clock) => {
    setClock(clock);
  };

  const getCountry = (country) => {
    setCountry(country);
  };

  const getDatePicker = (datePicker) => {
    setDatePicker(datePicker);
  };

  const getCoordinates = (number) => {
    setCoordinates(number);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    form.setFieldsValue({
      latLang: [coordinates.markers.lat, coordinates.markers.lng],
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    if(typeSubscripe === "day"){
      navigate(`/cars`, {
        state: {
          city_id: country ? country : 1,
          from: datePicker
            ? new Date(datePicker).getDate() +
              "/" +
              parseInt(datePicker.getMonth() + 1) +
              "/" +
              datePicker.getFullYear()
            : new Date().getDate() +
              "/" +
              parseInt(new Date().getMonth() + 1) +
              "/" +
              new Date().getFullYear(),
          numnerDay: day ? day : "1",
          numnerMonth:undefined,
          clock_from: clock ? clock : "11:00:00",
          lat: coordinates?.markers?.lat,
          lng: coordinates?.markers?.lng,
          need_delivery: coordinates ? 1 : 0,
        },
      });
    }else if(typeSubscripe === "month"){
      navigate(`/cars`, {
        state: {
          city_id: country ? country : 1,
          from: datePicker
            ? new Date(datePicker).getDate() +
              "/" +
              parseInt(datePicker.getMonth() + 1) +
              "/" +
              datePicker.getFullYear()
            : new Date().getDate() +
              "/" +
              parseInt(new Date().getMonth() + 1) +
              "/" +
              new Date().getFullYear(),
          numnerDay: undefined,
          numnerMonth: month ? month : "1",
          clock_from: clock ? clock : "11:00:00",
          lat: coordinates?.markers?.lat,
          lng: coordinates?.markers?.lng,
          need_delivery: coordinates ? 1 : 0,
        },
      });
    }
   
  };

  const onChange = (checked) => {
    setStateMap(checked);
  };

  const onChangeTime = (e) => {
    if (e.target.value === "1") {
      setTypeSubscripe("day");
    } else if (e.target.value === "2") {
      setTypeSubscripe("month");
    }
  };

  return (
    <>
      <Helmet>
        <title> أجرها | الرئيسية </title>
      </Helmet>
      <header className="main-header">
        <img src={logo} alt="logo" />
      </header>
      <div className="app-content bg-light">
        <h3 className="mb-4">استأجر سيارتك الأن</h3>

        <Form
          form={form}
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            clock_from: "11:00:00",
            numnerDay: "1",
            numnerMonth: "1",
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <h6 className="mb-2">استلام السيارة</h6>
          <Form.Item>
            <DrawerCommponent getCountry={getCountry} type="country" />
          </Form.Item>

          <Row gutter={10}>
            <Col xs={12}>
              <Form.Item name="date_from">
                <Form.Item>
                  <DrawerCommponent
                    getDatePicker={getDatePicker}
                    type="datePicker"
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item>
                <DrawerCommponent getClock={getClock} type="clock" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} className="text-center mb-5">
              <Radio.Group onChange={onChangeTime} defaultValue="1">
                <Radio.Button value="1">يومي</Radio.Button>
                <Radio.Button value="2">شهري</Radio.Button>
              </Radio.Group>
            </Col>
            {typeSubscripe === "day" ? (
              <Col xs={24}>
                <Form.Item>
                  <DrawerCommponent getDay={getDay} type="day" />
                </Form.Item>
              </Col>
            ) : typeSubscripe === "month" ? (
              <Col xs={24}>
                <Form.Item>
                  <DrawerCommponent getMonth={getMonth} type="month" />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="text-gray">توصيل السيارة لمكان محدد</h6>
            <Switch onChange={onChange} />
          </div>
          {!!stateMap && stateMap ? (
            <div className="mb-5">
              <Form.Item
                name="latLang"
                rules={[
                  {
                    required: true,
                    message: "الحقل مطلوب",
                  },
                ]}
              >
                <Input
                  readonly="readonly"
                  inputReadOnly={true}
                  onClick={showModal}
                  placeholder="تحديد موقع الاستلام"
                />
              </Form.Item>
            </div>
          ) : null}

          <Modal
            width="100%"
            className="modal-goggle-map"
            title={false}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="submit" type="primary" onClick={handleOk}>
                اختر العنوان
              </Button>,
            ]}
          >
            <GoogleMap
              getCoordinates={getCoordinates}
              setIsModalOpen={setIsModalOpen}
            />
          </Modal>

          <Form.Item>
            <Button type="yellow" block htmlType="submit">
              بحث
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Home;
