import "antd/dist/reset.css";
import Layout from "./components/Layout/Layout";
import "./styles/main.scss";
import IconWhts from "./assets/images/whatsapp-icon.png";
import { ConfigProvider } from "antd";
import { Link } from "react-router-dom";
import { getColors, getWhats } from "./store/actions/constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const { whats } = useSelector((state) => state.whatsapp);
  const { color, loading } = useSelector((state) => state.primaryColor);
  useEffect(() => {
    dispatch(getColors());
    dispatch(getWhats());
  }, [dispatch]);

  document.documentElement.style.setProperty("--primary-color", color);

  return (
    <ConfigProvider
          direction="rtl"
          theme={{
            token: {
              colorPrimary: !!color,
            },
          }}
        >
          <Layout />
          {!localStorage.getItem("user") ? (
            <Link
              onClick={() => {
                const url = `http://api.whatsapp.com/send?phone=${whats}`;
                window.open(url);
              }}
              className="icon-whts"
            >
              <img src={IconWhts} />
            </Link>
          ) : null}
        </ConfigProvider>
  );
}

export default App;
