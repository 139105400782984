
// CAR
export const CAR_REQUEST = 'CAR_REQUEST';
export const CAR_SUCCESS = 'CAR_SUCCESS'
export const CAR_ERROR = 'CAR_ERROR'


// CARDETAILS
export const CARDETAILS_REQUEST = 'CARDETAILS_REQUEST';
export const CARDETAILS_SUCCESS = 'CARDETAILS_SUCCESS'
export const CARDETAILS_ERROR = 'CARDETAILS_ERROR'



// CAR
export const SUBCATEGORIES_REQUEST = 'SUBCATEGORIES_REQUEST';
export const SUBCATEGORIES_SUCCESS = 'SUBCATEGORIES_SUCCESS'
export const SUBCATEGORIES_ERROR = 'SUBCATEGORIES_ERROR'

// CAR
export const SUBCATEGORIESMODAL_REQUEST = 'SUBCATEGORIESMODAL_REQUEST';
export const SUBCATEGORIESMODAL_SUCCESS = 'SUBCATEGORIESMODAL_SUCCESS'
export const SUBCATEGORIESMODAL_ERROR = 'SUBCATEGORIESMODAL_ERROR'



// CAR
export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS'
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR'