import { useEffect, useState } from "react";
import { Button, Form, Modal, Select, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";

import filter from "../../../assets/images/svg/filter.svg";
import { getCars, getSubcategoriesModal } from "../../../store/actions/car";

const { Option } = Select;

const Filter = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [countryid, setCountryid] = useState("");
  const [city, setCity] = useState(null);

  const { dataSubcategoriesModel } = useSelector((state) => state.subcategoriesModel);

  useEffect(() => {
    dispatch(getSubcategoriesModal(80));
  }, [dispatch]);

  // Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
  }, [city, countryid, dataSubcategoriesModel]);

  const handleChange = (value) => {
    const getcountryId = value;
    const getStatedata = dataSubcategoriesModel?.data[0]?.subcategories?.find(
      (item) => item.id === getcountryId
    );
    setCity(getStatedata);
    setCountryid(getcountryId);
  };



  const onFinish = (values) => {
    // console.log(values)
    dispatch(
      getCars({
        params: {
          categories:[values?.company , values?.date,  values?.model],
          cost_from:!!values?.price ? values?.price[0] : null,
          cost_to:!!values?.price ? values?.price[1] : null,
        },
      })
    );
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className="ant-btn-black btn-filter pointer mr-2"
        onClick={showModal}
      >
        <img src={filter} alt="" />
      </button>
      <Modal
        title={false}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
        onFinish={onFinish}
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          
        >
          <div className="pt-7">

          <Form.Item name="company" label="الشركة">
            <Select placeholder="اختر .." onChange={handleChange}>
              {dataSubcategoriesModel?.data[0]?.subcategories?.map((item) => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          {city != null ? (
            <Form.Item name="model" label="النوع">
              <Select placeholder="اختر ..">
                {city?.subcategories?.map((item) => (
                  <>
                    {item?.subcategories?.map((item) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                  </>
                ))}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item name="date" label="موديل الصنع">
            <Select placeholder="اختر .." >
              {dataSubcategoriesModel?.data[1]?.subcategories?.map((item) => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className="pb-0 mb-0"
            label="السعر"
            name="price"
          >
            <Slider range defaultValue={[20, 200]} min={5} max={1000} />
          </Form.Item>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <p>1000</p>
              <p>5</p>
            </div>
          <Form.Item>
            <Button type="black" block htmlType="submit">
              بحث
            </Button>
          </Form.Item>
          </div>

        </Form>
      </Modal>
    </>
  );
};

export default Filter;
