import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Routes from "../../routes/Routers";
import { useLocation } from "react-router-dom";
const Layout = () => {
  const location = useLocation();
  return (
    <div className="app">
      {/* {location.pathname != '/cars' ? <Header /> : null} */}

      <Routes />
      {location.pathname != "/login" &&
      location.pathname != "/forget-password" &&
      location.pathname != "/verify-password" &&
      location.pathname != "/register/verify" &&
      location.pathname != "/payment/details" &&
      location.pathname != "/register" ? (
        <Footer />
      ) : null}
    </div>
  );
};

export default Layout;
