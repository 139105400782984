import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import marker from "../../assets/images/svg/marker.svg";

const AnyReactComponent = () => <img src={marker} />;
const GoogleMap = (props) => {
  const [markers, setMarkers] = useState({
    lat: 24.774036498832565,
    lng: 46.652418028722494,
  });

  const defaultProps = {
    center: {
      lat: 24.774036498832565,
      lng: 46.652418028722494,
    },
    zoom: 9,
  };

  const onMarkerDragEnd = (e) => {
    setMarkers({ lat : e[0] , lng: e[1] });
  };

  useEffect(() => {
    props.getCoordinates({ markers });
  }, [markers]);


  return (
    <>
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBIh5NfhUi3L0e7fRwln75sUoLoySJ4e7I" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onBoundsChange={onMarkerDragEnd}
        >
          <AnyReactComponent lat={markers.lat} lng={markers.lng} />
        </GoogleMapReact>
      </div>
    </>
  );
};
export default GoogleMap;
