

import { CARDETAILS_ERROR, CARDETAILS_REQUEST, CARDETAILS_SUCCESS, CAR_ERROR, CAR_REQUEST, CAR_SUCCESS ,  CATEGORIES_ERROR,  CATEGORIES_REQUEST,  CATEGORIES_SUCCESS,  SUBCATEGORIESMODAL_ERROR,  SUBCATEGORIESMODAL_REQUEST,  SUBCATEGORIESMODAL_SUCCESS,  SUBCATEGORIES_ERROR, SUBCATEGORIES_REQUEST, SUBCATEGORIES_SUCCESS } from "./types.js";
import axiosApp from "../../../api/index.js";

export const getCars = ({ callback, params}) => async (dispatch) => {
  console.log(params)
  try {
    dispatch({
      type: CAR_REQUEST,
    });
    const response = await axiosApp.get(`/client-advertisements/list`, {
      params 
    });
    // console.log(response)
    dispatch({
      type: CAR_SUCCESS,
      payload: response.data,
    });
    callback?.()
  } catch (error) {
    dispatch({
      type: CAR_ERROR,
      payload: error.response.data.message,
    });
  }
};

export const resetCarsState = () => (dispatch) => {
  dispatch({
    type: CAR_SUCCESS,
    payload: undefined
  })
}



export const getSubcategories = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SUBCATEGORIES_REQUEST,
    });
    const response = await axiosApp.get(`/constants/${id}/subcategories`);
    dispatch({
      type: SUBCATEGORIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SUBCATEGORIES_ERROR,
      payload: error.response.data.message,
    });
  }
};


export const getSubcategoriesModal = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SUBCATEGORIESMODAL_REQUEST,
    });
    const response = await axiosApp.get(`/constants/${id}/subcategories`);
    dispatch({
      type: SUBCATEGORIESMODAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SUBCATEGORIESMODAL_ERROR,
      payload: error.response.data.message,
    });
  }
};


export const getCarDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CARDETAILS_REQUEST,
    });
    const response = await axiosApp.get(`client-advertisements/${id}/show`);
    dispatch({
      type: CARDETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: CARDETAILS_ERROR,
      payload: error.response.data.message,
    });
  }
};


export const getCategories = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORIES_REQUEST,
    });
    const response = await axiosApp.get(`/constants/categories`);
    dispatch({
      type: CATEGORIES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORIES_ERROR,
      payload: error.response.data.message,
    });
  }
};