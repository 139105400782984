import { Button, Checkbox, Col, Empty, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getCarDetails } from "../../../store/actions/car";
import dollar from "../../../assets/images/svg/dollar.svg";
import chevron from "../../../assets/images/svg/chevron-right.svg";

const CarDetails = (props) => {
  const { onClickOutside } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [accessoriesArray, setAccessoriesArray] = useState([]);

  let { id } = useParams();

  const { state } = useLocation();

  const { dataCarDetails, loading, error } = useSelector(
    (state) => state.carDetails
  );
  const [accessoriesArray, setAccessoriesArray] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [stateChecked, setStateChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getCarDetails(id));
    // console.log(state)
  }, [dispatch]);

  useEffect(() => {
    // console.log(accessoriesArray.reduce((n, {cost}) => n + cost, 0))
  }, [accessoriesArray]);

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      setStateChecked(true);
    } else {
      setStateChecked(false);
    }
  };

  const goDetails = () => {
    if (stateChecked) {
      navigate(`/payment/details`, {
        state: {
          price: dataCarDetails?.price,
          discount: dataCarDetails?.discount,
          id: dataCarDetails?.id,
          from: state?.from,
          type_id: state?.type_id,
          period: state?.period,
          need_delivery: 0,
          accessories: accessoriesArray,
          lat: state?.lat,
          lng: state?.lng,
          clock_from:state?.clock_from,
        },
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    return () => {};
  }, [props]);

  useEffect(() => {
    // const handleClickOutside = (event) => {
    //   if (ref.current && !ref.current.contains(event.target)) {
    //     onClickOutside && onClickOutside();
    //   }
    // };
    document.querySelector(".app-footer")?.classList?.add("d-none");
    return () => {
      document.querySelector(".app-footer")?.classList?.remove("d-none");
    };
  }, [onClickOutside]);

  if (state === null) {
    navigate("/");
  }

  return (
    <>
      <Helmet>
        <title> أجرها | سيارات </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <div onClick={() => navigate(-1)}>
            <img src={chevron} className="p-1 pointer icon" />
          </div>
        </Row>
      </header>
      <div className="app-content">
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : error ? (
          error
        ) : (
          <>
            {!!dataCarDetails ? (
              <div>
                <h3 className="mb-5"> {dataCarDetails.title}</h3>
                <div className="car-single-image text-center mb-4">
                  <img src={dataCarDetails.image} alt={dataCarDetails.name} />
                </div>
                <Row gutter={10}>
                  {dataCarDetails?.accessories?.map((item, index) => (
                    <Col span={8}>
                      <label className="widget__item-serv">
                        <input
                          onClick={(e) => {
                            // add to list
                            if (e.target.checked) {
                              setAccessoriesArray([
                                ...accessoriesArray,
                                {
                                  id: item.id,
                                  name: item.name,
                                  cost: item.cost,
                                },
                              ]);
                            } else {
                              // remove from list
                              setAccessoriesArray(
                                accessoriesArray.filter(
                                  (people) => people.id !== item.id
                                )
                              );
                            }
                          }}
                          type="checkbox"
                        />
                        <div className="widget__item-serv-content">
                          <div className="widget__item-icon mb-1">
                            <img
                              src={dollar}
                              alt={item.name}
                              style={{
                                width: "1.5rem",
                                height: "auto",
                              }}
                            />
                          </div>
                          <h6 className="widget__item-price mb-1">
                            <span className="font-medium">{item.cost}</span>
                            <span className="font-12 text-gray ms-2">ريال</span>
                          </h6>
                          <h6 className="widget__item-title font-12 text-gray">
                            {item.name}
                          </h6>
                        </div>
                      </label>
                    </Col>
                  ))}
                </Row>
                <h5
                  className="my-2"
                  dangerouslySetInnerHTML={{
                    __html: dataCarDetails?.description,
                  }}
                ></h5>
                <Checkbox onChange={onChange} className="my-2">
                  أوافق على{" "}
                  <Link className="font-bold" to="/privacy">
                    الشروط وسياسة الخصوصية
                  </Link>
                </Checkbox>
                <div className="fixed-footer">
                  <Row className="mt-7">
                    <Col span={9}>
                      {dataCarDetails.discount ? (
                        <h6 className="text-gray line-through font-medium">
                          {dataCarDetails.discount}
                        </h6>
                      ) : null}
                      <h5>
                        <span className="font-bold">
                          {dataCarDetails.price +
                            accessoriesArray?.reduce(
                              (n, { cost }) => n + cost,
                              0
                            )}
                        </span>
                        <span className="text-gray font-12 mr-2">
                          ريال / يوم
                        </span>
                      </h5>
                    </Col>
                    <Col span={15}>
                      <div
                        onClick={goDetails}
                        // to="/payment/details"
                        // state={{
                        //   price: dataCarDetails?.price,
                        //   discount: dataCarDetails?.discount,
                        //   id: dataCarDetails?.id,
                        //   from: state?.from,
                        //   numnerDay: state?.numnerDay,
                        //   numnerMonth: state?.numnerMonth,
                        //   need_delivery: 0,
                        //   accessories: accessoriesArray,
                        //   lat: state?.lat,
                        //   lng: state?.lng,
                        // }}
                        className="ant-btn-yellow btn-block"
                        size="large"
                        block
                      >
                        احجز الآن
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <Empty />
            )}
          </>
        )}
      </div>
      <Modal
        footer={false}
        title={false}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <div className="pb-3">
          <p className="mb-4">يجب عليك الموافقة على الشروط والاحكام</p>
          <div className="text-center">
            <Button onClick={handleCancel} type="primary">
              موافق
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CarDetails;
